<template>
    <div>
        <div class="columns is- mt-2 ">
            <div  v-for="order in orders" :key="order._id" class="column  is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                <div class=" p-3">
                    <div @click="viewDetails(order)" class="mb-3">
                        <div class="columns is-mobile">
                            <div class="column is-two-thirds">
                                <h4 class="title is-5 mb-3"> {{ order.username }} </h4>
                                <h6 class="is-5 mb-2 has-text-weight-medium">GH₵{{ formatPrice(order.totalPrice) }}</h6>
                                <p class="subtitle is-6 "> {{ formatAMPM( new Date(order.createdAt)) }}</p>
                            </div>
                            <div class="column ">
                                <b-tag rounded :type=" (order.orderDelivered !== null ? 'is-success' : order.cancelled ? 'is-danger' : order.confirmed == null ? 'is-warning' : ( order.courierAssigned || order.courierDelivering ? 'is-info' : 'is-light'))" class="my-2">{{ order.status }}</b-tag>
                            </div>
                        </div>
                    </div>
                    <div v-if="order.confirmed == null && order.cancelled == null" class="columns is-mobile">
                        <div class="column is-half p-1">
                            <b-button @click="confirmOrder(order)" expanded rounded type="is-primary">Confirm </b-button>
                        </div>
                        <div class="column p-1">
                            <b-button disabled @click="CancelTemplate(order)" expanded rounded outlined >Cancel </b-button>
                        </div>
                    </div>
                    <div v-if="order.status == 'confirmed'" class="columns is-mobile">
                        <!-- <div class="column is-half p-1">
                            <b-button @click="confirmOrder(order)" expanded rounded type="is-primary">Track </b-button>
                        </div> -->
                        <div class="column p-1">
                            <b-button @click="viewCouriers(order.pickupRequest, order.orderNo)" expanded rounded outlined type="is-primary">View Couriers </b-button>
                        </div>
                    </div>
                    <div v-if="order.status == 'assigned' || order.status == 'delivering'" class="columns is-mobile">
                        <div class="column ">
                            <b-button tag="a" :href="`tel:${order.pickupRequest.find(cour=> cour.name == order.courier).phone.countryCode+order.pickupRequest.find(cour=> cour.name == order.courier).phone.tel}`" icon-left="phone" class="is-flex is-justify-content-center is-align-items-center" expanded rounded type="is-primary"> <span>{{ order.courier }}</span> </b-button>
                        </div>
                    </div>
                </div>
            <hr class="my-2 mt-4">
            </div>
        </div>
        <!-- Modal -->
        <b-modal v-if="isCardModalActive" v-model="isCardModalActive" scroll="keep">
            <div class="p-2 card">
                <div class="p-4">
                    <h3 class="title is-5">{{ response }}</h3>
                    <p class="subtitle">Details below</p>
                </div>
               <div  v-for="courier in couriers" :key="courier._id" class="column  is-half-tablet is-half-desktop is-one-third-widescreen is-one-quarter-fullhd">
                    <div class="p-3">
                        <div class="">
                            <div class="columns is-mobile">
                                <div class="column is-four-fifths">
                                    <h4 class="title is-5 "> {{ courier.name }} </h4>
                                    <p class="subtitle is-6 "> {{ courier.phone.countryCode+courier.phone.tel }}</p>
                                </div>
                                <div class="column is-flex is-justify-content-right">
                                    <a :href="`tel:${courier.phone.countryCode+courier.phone.tel}`" class="is-primary">
                                        <feather type="phone"></feather>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2 mt-4">
               </div>
                
            </div>
        </b-modal>
    </div>
</template>

<script>

    export default {
        name: 'OrderTemplate',
        data() {
            return {
                isCardModalActive: false,
                currentProduct: {},
                screenWidth: 0,
                couriers: null,
                response: ""
            }
        },
        props: {
            orders: {
                required: true
            },
        },
        mounted() {
            this.updateScreenWidth();
            this.onScreenResize();
        },
        watch: {
            isCardModalActive() {
                if (this.isCardModalActive == false) {
                    this.$store.dispatch('orders/getOrders')
                }
            }
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            
        },
        methods: {
            formatAMPM(date) {
                var h = date.getUTCHours();
                var m = date.getUTCMinutes();
                var ampm = h >= 12 ? 'pm' : 'am';
                h = h % 12;
                h = h ? h : 12;
                m = m < 10 ? '0'+m : m;
                var strTime = h + ':' + m + ' ' + ampm;
                return date.toDateString()+', '+strTime;
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            formatPrice(price) {
                return price.toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            getImage(img) {
                const fullPath = '../assets/img/'+img
                console.log(fullPath)
                return fullPath
            },
            viewDetails(order){
                if (order){
                    //
                    // this.items = order.items
                    // this.isCardModalActive = true
                    this.$router.push({ name: 'orderdetails', params: { orderNo: order.orderNo }  })
                }
            },
            confirmOrder(order){
                if (order){
                    this.$buefy.dialog.confirm({
                        title: 'Confirm order',
                        message: 'Tap below to confirm.',
                        confirmText: 'Confirm',
                        onConfirm: () => {
                            // axios
                            this.$axios.post('/order/status/vendor', { action: 'confirmed', orderNo: order.orderNo, vendor: order.vendor }).then(res => {
                                //
                                this.$buefy.toast.open(res.data.message)

                                //
                                if (res.data.data) {
                                    //
                                    this.isCardModalActive =true
                                    this.couriers = res.data.data
                                    this.response = res.data.message
                                }

                            }).catch(error=> {
                                console.log(error)
                            })
                        }
                    })
                }
            },
            CancelTemplate(order){
                if (order){
                    this.$buefy.dialog.confirm({
                        title: 'Cancel Order',
                        message: `Are you sure you want to cancel order?`,
                        confirmText: 'Yes',
                        onConfirm: () => {
                            // axios
                            this.$axios.post('/order/status/vendor', { action: 'cancelled', orderNo: order.orderNo, vendor: order.vendor }).then(res => {
                                //
                                this.$buefy.toast.open('Successful, Order is cancelled')
                                //
                                this.$store.dispatch('orders/getOrders')

                            }).catch(error=> {
                                console.log(error)
                            })
                        }
                    });
                }
            },
            viewCouriers(data, orderNo) {
                this.couriers = data
                this.response = "Couriers assigned to "+orderNo.split('-')[0]
                this.isCardModalActive = true
            }
        } 
    }
</script>

<style lang="scss" >// custom
    @import '@/assets/scss/custom_variables.scss';
    
    .modal-close {
        background: gray !important;
    }

    .order-cover {
        position: fixed;
        z-index: 50;
        width: calc(100% - 50px);
        // left: 0;
        // padding-bottom: 20px;
        margin-bottom: 5px;
        bottom: 10px;
    }
</style>