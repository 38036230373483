<template>
    <div>
        <div class="columns" >
                <div class="column">
                    <b-input type="text" placeholder="Search address" class="form-control" ref="locationSearch" @changed="changed"></b-input>
                </div>
			<div style="height:100vw;width:auto" ref="map">

			</div>
		</div>
    </div>
</template>

<script>
    //

    export default {
        name: "GoogleMaps",
        data() {
            return {
                home: {
					_geoloc : {
						lat: 5.5912931, lng: -0.2147489
					}
				},
            }
        },
        mounted() {
            // this.$maps.showMap(this.$refs.map, this.home._geoloc.lat, this.home._geoloc.lng)

            // this.$maps.makeAutoComplete(this.$refs.locationSearch)
        },
        methods: {
            changed(event) {
                const place = event.detail;

                if (!place.geometry) return

                // this.$router.push({
                // 	name: 'search',
                // 	query: {
                // 		lat: place.geometry.location.lat(),
                // 		lng: place.geometry.location.lng(),
                // 		label: this.$refs.locationSearch.value,
                // 	}
                // })

                this.home._geoloc.lat = place.geometry.location.lat();
                this.home._geoloc.lng = place.geometry.location.lng();

                this.$maps.showMap(this.$refs.map, this.home._geoloc.lat, this.home._geoloc.lng)


            }
        }
    }
</script>

<style lang="scss" scoped>

</style>