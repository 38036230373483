import Vue from 'vue'
import Router from "vue-router";
import { authHeader } from '../helpers/auth-header'
import Userauth from '../auth/index'
import { userService } from '../services';

import MainBody from '../components/body'
import MainAuth from '../components/auth'

import DashboardPage from '../pages/dashboard'
import IndexPage from '../pages/index'
import WalletPage from '../pages/wallet'
import NotificationPage from '../pages/notification'
import SearchPage from '../pages/search'
import InsightsPage from '../pages/insights'
import ProfilePage from '../pages/profile'
import CheckoutPage from '../pages/checkout'
import MapView from '../pages/mapview'
import EarningsPage from '../pages/earnings'
import ProductPage from '../pages/product'
import ProductsPage from '../pages/products'
import CartPage from '../pages/cart'
import OrderDetailsPage from '../pages/orderDetails'

import SigninPage from '../pages/signin'
import SignupPage from '../pages/signup'
import SetupPage from '../pages/setup'
import VerifyotpPage from '../pages/verifyotp'

// import { store } from '../store'

//
Vue.use(Router)

const routes = [
    { path: '', redirect: { name: 'index' }},
    { path: '/', redirect: { name: 'index' }},
    {
        path: '/',
        component: MainBody,
        children:[
            {
                path: 'home',
                name: 'home',
                component: DashboardPage,
                meta: {
                    title: 'Home | Dashboard',
                }
              },
              {
                path: 'index',
                name: 'index',
                component: IndexPage,
                meta: {
                    title: 'Home | Welcome',
                }
              },
              {
                  path: 'search',
                  name: 'search',
                  component: SearchPage,
                  meta: {
                      title: 'Search',
                  }
              },
              {
                  path: 'insights',
                  name: 'insights',
                  component: InsightsPage,
                  meta: {
                      title: 'Insights',
                  }
              },
              {
                path: 'checkout',
                name: 'checkout',
                component: CheckoutPage,
                meta: {
                    title: 'Checkout',
                }
              },
              {
                path: 'cart',
                name: 'cart',
                component: CartPage,
                meta: {
                    title: 'Cart',
                }
              },
              {
                path: 'mapview',
                name: 'mapview',
                component: MapView,
                meta: {
                    title: 'Mapview',
                }
             },
             {
                path: 'earnings',
                name: 'earnings',
                component: EarningsPage,
                meta: {
                    title: 'Earnings',
                }
             },
             {
                path: 'product',
                name: 'product',
                component: ProductPage,
                meta: {
                    title: 'Product',
                }
             },
             {
                path: 'products',
                name: 'products',
                component: ProductsPage,
                meta: {
                    title: 'Products',
                }
             },
              {
                path: 'profile',
                name: 'profile',
                component: ProfilePage,
                meta: {
                    title: 'Profile',
                }
              },
              {
                path: 'notification',
                name: 'notification',
                component: NotificationPage,
                meta: {
                    title: 'Notification',
                }
            },
            {
                path: 'orderdetails',
                name: 'orderdetails',
                component: OrderDetailsPage,
                meta: {
                    title: 'Order Details Page',
                }
            },
        ]
    },
    {
        path: '/',
        component: MainAuth,
        children: [
            {
                path: 'signin',
                name: 'signin',
                component: SigninPage,
                meta: {
                    title: 'Signin Here',
                }
            },
            {
                path: 'signup',
                name: 'signup',
                component: SignupPage,
                meta: {
                    title: 'Signup Here',
                }
            },
            {
                path: 'setup',
                name: 'setup',
                component: SetupPage,
                meta: {
                    title: 'Setup Here',
                }
            },
            {
                path: 'verifyotp',
                name: 'verifyotp',
                component: VerifyotpPage,
                meta: {
                    title: 'verify OTP Here',
                }
            },
        ]
    }
]



 //
//  axios.get('/getAll').then(res=>{
//     console.log(res.data)
//  })

const router = new Router({
    base: '/',
    mode: 'history',
    routes,
    linkActiveClass: "active",
    scrollBehavior () {
      return { x: 0, y: 0 }
    }
})


// const axios = require('axios')

// token
// let token = authHeader()['Authorization']

// console.log(token)

// authenticate 
router.beforeEach((to, from, next) => {
   
    // default routes without token
    if (['/signin', '/verifyotp', '/setup'].includes(to.path)){
        return next();
    } else {
        // return next();
        // user
        const User = localStorage.getItem('user')
        // check for token and token expiry
        if (['/signin','/setup', 'verifyotp'].includes(to.path) || (User && Userauth.isAuthenticated()) ){
            return next();
        } else {
            // token has expired

            // user
            const User = JSON.parse(localStorage.getItem('user'))
            // validate
            if (!User) {
                return next('/signin')
            }
            // initiate a refresh request
            userService.refresh(User.refresh_token).then(res=>{
                //
                if (['/signin','/setup', 'verifyotp'].includes(to.path) || (User && Userauth.isAuthenticated())) {
                    return next();
                }

            }).catch(error=>{
                //
                Userauth.logout()
                //
                return next('/signin')
             })

        }

        if(to.meta.title)
            document.title = to.meta.title;

        // return next('/login')
    }

  
      
})

export default router