<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav text=" 00.00" />
        </div>
        <div class="p-3">
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-5"> Wallet </p>
                            <h4 class="subtitle is-5">Total </h4>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right	">
                            <h4 class="has-text-weight-medium">GHC{{ getTotals(completed).toFixed(2) }}</h4>
                        </div>
                    </div>
                    <hr class="mt-3">
                    <div class="columns is-mobile">
                        <div class="column is-half">
                            <div class="">
                                <p class="title is-5"> Pay Outs </p>
                                <h4 class="subtitle is-5">GHC0.00</h4>
                            </div>
                        </div>
                        <div class="column is-flex is-justify-content-right">
                            <div class=" ">
                                <p class="title is-5"> Pay Ins </p>
                                <h4 class="subtitle is-5">GHC0.00</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-5"> Today </p>
                            <h4 class="subtitle is-5">Total (0)</h4>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right	">
                            <h4 class="has-text-weight-medium">GHC0.00</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-5"> Weekly </p>
                            <h4 class="subtitle is-5">Total (0)</h4>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right	">
                            <h4 class="has-text-weight-medium">GHC0.00</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-3">
                <div class="card p-5">
                    <div class="columns is-mobile">
                        <div class="column is-6">
                            <p class="title is-5"> Bonus</p>
                            <h4 class="subtitle is-5">Total </h4>
                        </div>
                        <div class="column is-flex is-align-items-center is-justify-content-right	">
                            <h4 class="has-text-weight-medium">GHC0.00</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <MainFooter/> -->
    </div>
</template>

<script>

            
    import { mapGetters, mapActions} from 'vuex'

    import MainFooter from '../components/footer'
    import SideNav from '../components/sidenav'

    export default {
        name: "EarningsPage",
        components: {
            // MainFooter,
            SideNav
        },
        computed: {

            ...mapGetters('orders', ['getOrders']),
            ...mapGetters('orders', ['pending']),
            ...mapGetters('orders', ['confirmed']),
            ...mapGetters('orders', ['completed']),
            ...mapGetters('orders', ['cancelled']),
            
        },
        mounted() {
            //
            this.getAllOrders()
        },
        methods: {
            getTotals(obj){
               const sum  = obj. reduce((accumulator, object) => {
                    return accumulator + object.totalPrice;
                }, 0)

                return sum
            },
            alert(){
                console.log(this.$route)
            },
            async getAllOrders() {

                await this.$store.dispatch('orders/getOrders')

            }
        }
    }
</script>

<style lang="scss" scoped>
    .card {
        border: 1px solid rgba(10, 10, 10, 0.1) !important;
        box-shadow: none;
    }
</style>