<template>
    <div>
        <!-- <div class="px-3 py-3 top-nav" style="box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;position:fixed;width:100%;background:white"> -->
        <div class="px-2 py-1 top-nav main" style="">
            <div class="columns is-mobile">
                <div class="column is-2 ">
                    <feather size="22" type="menu" class="link- link p-3 white" @click="open = true"></feather>
                </div>
                <div class="column is-8 ">
                    <h2 class="title is-5 has-text-white"> {{ header }} </h2>
                    
                </div>
                <div class="column is-2">
                    <feather v-if="feather" ref="headerButton" size="22" :type="feather" class="link- link p-3 white" @click="action"></feather>
                    <p class="title is-6 white" ref="headerText" v-if="text && !text.includes(',')">{{ text }}</p>
                    <p class="title is-6 white" ref="headerText" v-else-if ="text && text.includes(',')"><span class="small"> {{ text.split(',')[0] }} </span> {{ text.split(',')[1] }}</p>
                </div>
            </div>
        </div>

        <b-sidebar
            type="is-light"
            :fullheight="fullheight"
            :fullwidth="fullwidth"
            :overlay="overlay"
            :right="right"
            v-model="open"
        >
        <div class="p-3 px-4">
            <div class="p-3">
                <h3 class="title is-4 mb-1 pt-3">
                    {{ getUser.business }}
                </h3>
                <h6 class="has-text-grey is-6">{{ getUser.type }}</h6>
            </div>
            <hr class="my-2">
            <b-menu>
            <b-menu-list label="">
                <b-menu-item @click.native="open = false" :active="checkRoute('index')" type="is-primary" label="Orders" tag="router-link" to="/" >
                </b-menu-item> 
                <b-menu-item @click.native="open = false" :active="checkRoute('insights')" label="Insights" tag="router-link" to="/insights" > 
                </b-menu-item>
                <b-menu-item @click.native="open = false" :active="checkRoute('products')" label="Products" tag="router-link" to="/products" ></b-menu-item>
                <b-menu-item @click.native="open = false" :active="checkRoute('earnings')" label="Earnings" tag="router-link" to="/earnings" ></b-menu-item>
                <b-menu-item @click.native="open = false" :active="checkRoute('profile')" label="Profile" tag="router-link" to="/profile" ></b-menu-item>
                
            </b-menu-list>
            <hr class="m-2">
            <b-menu-list label="">
                <!-- <b-menu-item @click.native="open = false" label="Account" tag="router-link" to="/account" ></b-menu-item> -->
                <b-menu-item @click.native="logOut" label="Sign out" tag="a" ></b-menu-item>
            </b-menu-list>

            <b-menu-list label="">
                <!-- <span class="px-2 py-4" style="position:absolute;bottom:0">&copy;  myHealthSusu. </span> -->
            </b-menu-list>
            </b-menu>
        </div>
        </b-sidebar>
    </div>
</template>

<script>

    import { userService } from '../services';
    import { mapGetters } from 'vuex'
 
    export default {
        name: "SideNav",
        props: {
            feather: {
                type: String,
                default: null
            },
            action: {
                default: null
            },
            text: {
                type: String,
                default: null
            }
        },
        data() {
            return {
                header: '',
                openUser: false,
                open: false,
                overlay: false,
                fullheight: true,
                fullwidth: false,
                right: false,
                isCardModalActive: false
            }
        },
        computed: {
            ...mapGetters('cart', ['cartCount']),
            ...mapGetters('auth', ['getUser'])
        },
        methods: {
            checkRoute(link){
                this.header = this.$route.name == 'index' ? 'My Orders' : this.$route.name.toUpperCase().substring(0,1)+this.$route.name.substring(1)
                return this.$route.name == link
                // console.log('hiiiii')
            },
            logOut() {
                this.open = false;
                //
                userService.logout().then(()=>{
                    //
                    this.$router.push('/signin')
                })
                //
                
            }
        }
    }
</script>

<style lang="scss" >
// custom
@import '@/assets/scss/custom_variables.scss';


.menu-list a.is-active {
    background-color: $primary !important;
}

.main .column {
    display: flex;align-items: center;
    justify-content: center;
}

.white {
    color: $white;
}

.main {
    background-color: gray;
}

.link-bg {
    background: rgb(246, 246, 246);
    border-radius: 1.10rem;
}

// .top-nav {
    // display: flex;
    // align-items: center;
    // align-content: space-between;
    // justify-content: space-between;
// }

.link {
    cursor: pointer;
}

.logo { 
    margin: auto;
    width: 200px;
}

.menu-list a {
    padding: 1rem 0.75rem !important;
}

.subtitle {
    font-size: 0.85rem !important;
    color: rgba($color: #828282, $alpha: 1.0);
}

.small {
    font-size: 0.8rem;
}
</style>