<template>
    <div>
        <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="true"></b-loading>
        <div class="pb-5">
            <!-- <h4 class="subtitle is-2 mb-0" style="text-align:center">Heath <i class="has-text-danger">Line</i></h4> -->
            <!-- <p style="text-align:center;font-weight:300" class="subtitle is-6 has-text-gray">your health in your palm</p> -->
        </div>
        <div class="auth">
            <div class=" px-3 py-4">
                <div class="-content">
                    <div class="content">
                        <div class="pb-1 text-center">
                            <img style="width:320px;" :src="require('@/assets/img/logo1.png')" class="logo "/>
                            <h1 class="title is-3 pt-3">Vendor<span style="font-weight:300;"></span></h1>
                            <!-- <p class="subtitle is-5 has-text-grey">Enter details below</p> -->
                        </div>
                        <div class="pt-5 pb-2">

                            <!-- <div class="columns is-mobile mb-0"> -->
                                <!-- <div class="column is-one-quarter-widesreen is-one-quarter-desktop is-one-quarter-tablet is-one-third-mobile"> -->
                                    <b-field label=""  :type="type" :message="message">
                                        <b-input icon="" type="email" @input="validateEmail()" v-model="form.email" autofocus autocomplete="1" placeholder="Email Address">
                                        </b-input>
                                    </b-field>
                                <!-- </div> -->
                                <!-- <div class="column"> -->
                                    <!--  @input.native="validateContact()" -->
                                    <b-field label="" :type="pstype" :message="psmessage">
                                        <b-input icon="" type="password" @input="validatePs()" @keyup.native.enter="onSubmit" autofocus v-model="form.password" placeholder="Password"></b-input>
                                    </b-field>
                                <!-- </div> -->
                            <!-- </div> -->
                            
                            <div  class="notification small text-center"> Enter email and password to signin.
                                <!-- <router-link  to="/setup">Sign up </router-link>
                                <router-link  to="/verifyotp">Verify </router-link>
                                <router-link  to="/index">index </router-link>
                                <router-link  to="/home">Home </router-link> -->
                                <!-- <vue-feather type="star"></vue-feather> -->
                            </div>
                            <b-field class=" text-center">
                                <b-button @click="onSubmit"  expanded class="is-submit-button" type="is-primary">Submit</b-button>
                            </b-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // import axios from 'axios'
    import { userService } from '../services';

    export default {
        name:'SigninPage',
         data() {
            return {
                isLoading: false,
                isFullPage: true,
                form: {
                country: '',
                contact: '',
                email: '',
                password: '',
                code: '+233',
                remember: true,
                },
                pstype: '',
                psmessage: '',
                type: '',
                message: '',
                show: true
            }
        },
        computed: {
            getUser() {
                return this.$store.getters['auth/getUser']
            }
        },
        mounted() {
            if (this.getUser) {
                //
                this.$router.push('/index')
            }
        },
        methods: {
            validateEmail() {

                const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)

                 if (this.form.email.match(reg)) {
                        this.type = 'is-success';
                        this.message =''
                        return true
                 } else {
                        this.type = 'is-danger';
                        this.message ='Kindly enter a valid email'
                        return false
                        
                 }

            },
            validatePs() {
                if (this.form.password.length < 3){
                    this.pstype = 'is-danger';
                    this.psmessage ='Password should be 3 or more characters'
                    return false
                }

                if (this.form.password.length >= 3){
                    this.pstype = 'is-success';
                    this.psmessage =''
                    return true
                }
            },
            openLoading() {
                this.isLoading = true
                setTimeout(() => {
                    this.isLoading = false
                }, 1.5 * 1000)
            },
            onSubmit(event) {
                // validate
                if (!this.validateEmail() || !this.validatePs()){
                    return
                }

                this.isLoading = true

                // 
                userService.login(this.form.email, this.form.password, this.form.remember ).then(response => {
                    //
                    // console.log(response)
                    // 
                    setTimeout(() => {
                        // this.type = response.type
                        this.$buefy.toast.open('Successful')

                        //
                        // this.$buefy.toast.open("Successfully Singned in!")
                        //
                        this.$router.push({ name: 'index' })

                     }, 1000)

                }).catch(error=>{
                    //
                    // console.log(error)
                    //
                    this.$buefy.toast.open(error.response.data)
                })

                // ajax
                // axios.post('/user/login', this.form).then(response=>{
                //     //
                //     console.log(response.data)
                //     // 
                //     this.form.type = response.data.type
                //     this.$buefy.toast.open(response.data.message)

                //     //

                // }).catch(error=>{
                //     //
                //     console.log(error)
                //     //
                //     this.$buefy.toast.open(error.response.data)
                // })

                setTimeout(() => {
                    this.isLoading = false

                    //
                    // this.$router.push({ name: 'verifyotp', params: { code: this.form.code, contact: this.form.contact, type: this.type } })

                    //
                    // this.$buefy.dialog.prompt({
                    //     // title: 'Verify Code',
                    //     message: `Enter the 4-digit OTP code received!`,
                    //     inputAttrs: {
                    //         placeholder: 'X X X X',
                    //         maxlength: 60
                    //     },
                    //     trapFocus: true,
                    //     onConfirm: (value) => {
                    //         //
                    //         this.form.OTP = value

                            

                    //     }
                    // })
                }, 1.2 * 1000)

                

                // alert(JSON.stringify(this.form))
            },
            onReset(event) {
                event.preventDefault()
                // Reset our form values
                this.form.email = ''
                this.form.name = ''
                this.form.food = null
                this.form.checked = []
                // Trick to reset/clear native browser form validation state
                this.show = false
                this.$nextTick(() => {
                this.show = true
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
.auth {
    // font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 460px;
    margin: auto;
    // margin-top: 3.5rem;
    text-align: left;
}


// input{
//     padding: 1.5rem;
//     margin: 0.75em auto;
// }

// .title {
//     font-weight: 700;
// }

// .subtitle {
//     color: rgba(125, 125, 125, 0.7)
// }

// button {
//     padding: 12px 35px;
//     border-radius: 5px;
// }
</style>