<template>
    <div>
        <div class="sidebar-wrapper">
            <SideNav />
        </div>
        <!-- {{ vendors }} -->
        <div class="p-5" :style="(screenWidth <= 560 ? '' : (screenWidth <= 768 ? 'max-width: 768px; margin: auto;' : 'max-width: 968px; margin: auto;') )">
            

            <!--  -->

            <b-tabs position="is-centered" type="is" >
                <b-tab-item color="is-success" icon="clock" label="PENDING">
                    <OrderTemplate v-if="pending.length > 0" :orders="pending" />
                </b-tab-item>

                <b-tab-item color="is-success" icon="check" label="CONFIRMED">
                    <template #header>
                       <span> <b-icon icon="check"></b-icon> CONFIRMED <b-tag rounded> {{ confirmed.length }} </b-tag> </span>
                    </template>
                    <OrderTemplate v-if="confirmed.length > 0" :orders="confirmed" />
                    <div v-else></div>
                </b-tab-item>
                <b-tab-item color="is-success" label="Completed" icon="checkbox-marked-outline">
                    <template #header>
                       <span> <b-icon icon="checkbox-marked-outline"></b-icon> COMPLETED <b-tag rounded> {{ completed.length }} </b-tag> </span>
                    </template>
                    <OrderTemplate v-if="completed.length > 0" :orders="completed" />
                    <div v-else></div>
                </b-tab-item>
                <!-- <b-tab-item color="is-success" label="ALL" icon="layers">
                    <OrderTemplate v-if="getOrders.length > 0" :orders="getOrders" />
                </b-tab-item> -->
                
            </b-tabs>

            <div>
                
            </div>
            <!-- <div class="space mt-2"></div> -->
            <!-- <div v-if="mostRated.length > 0">
                <div class="pt-4">
                    <h1 class="title is-5 has-text-weight-semibold"> <feather color="green" type="thumbs-up" size="22"></feather> Most Rated <span style="font-weight:300;"> </span> </h1>
                </div>
                <OrderTemplate v-if="mostRated.length > 0" :classes="'columns cov is-mobile'" :business="mostRated" />
                <VendorSkeleton v-else/>
            </div> -->

            
        </div>
        <!-- <MainFooter/> -->

    </div>
</template>

<script>

    import { mapGetters, mapActions} from 'vuex'
    
    // import gql from 'graphql-tag'
    import SideNav from '../components/sidenav'
    import VendorSkeleton from '../components/VendorSkeleton'
    import OrderTemplate from '../components/OrderTemplate'
    
    import MainFooter from '../components/footer'

    export default {
        name: 'IndexPage',
        data(){
            return {
                screenWidth: 0,
                lastPath: null
            }
        },
        components: {
            SideNav,
            // VendorSkeleton,
            OrderTemplate,
            // MainFooter
        },
        created() {
            // watch and fetch
            this.$watch(
            () => this.$route.$params,
            async () => {
                // this.$store.dispatch('vendors/getBusiness')
                
                // mapActions('vendors',['getBusiness'])
                this.getAllOrders()
                this.initCheckOrders()
            },
            { immediate: true }

            )

        },
        mounted(){
            this.updateScreenWidth();
            this.onScreenResize();
        },
        computed: {
            // ...mapGetters('cart', ['totalPrice']),
            // ...mapGetters('cart', ['cartCount']),
            // ...mapGetters('cart', ['getCartBusiness']),
            ...mapGetters('orders', ['getOrders']),
            ...mapGetters('orders', ['pending']),
            ...mapGetters('orders', ['confirmed']),
            ...mapGetters('orders', ['completed']),
        },
        methods: {
            getImgUrl(value) {
                return `/assets/img/slider/${value}`
            },
            onScreenResize() {
                window.addEventListener("resize", () => {
                    this.updateScreenWidth();
                });
            },
            updateScreenWidth() {
                this.screenWidth = window.innerWidth;
            },
            CheckOrders() {
                //
                this.getAllOrders()
            },
            initCheckOrders() {
                //
                const timer = setInterval(()=>{
                    //
                    this.CheckOrders()

                    if (this.$route.name !== 'index') {
                        // final step
                        clearInterval(timer)
                    }

                    if (this.orderAssigned) {
                        // final step
                        clearInterval(timer)
                    }

                }, 10*1000)

                // console.log(new Date)
            },
            async getAllOrders() {

                await this.$store.dispatch('orders/getOrders')

                // const resp = await this.$apollo.provider.defaultClient.query({ query: GET_LOCATION_BUSINESS_QUERY, variables: { location: [5.6305863,-0.1474174]} });
                
                // const resp = await this.$apollo.provider.defaultClient.query({

                //                 query: gql`query businesses {
                //                             businesses {
                //                                     _id
                //                                     business
                //                                     type
                //                                     coverImage
                //                                     location
                //                                     country
                //                                     freeDelivery
                //                                     deliveryFee
                //                                     deliveryTime
                //                                     menuDiscount
                //                                     ratings
                //                                 }
                //                             }
                //                             `
                //             });

                // (function () {
                //     const data = resp.data.businesses
                //     for (let index = 0; index < data.length; index++) {
                //         // const element = array[index];
                //         const rateLen = data[index].ratings.length

                //         if (rateLen > 0) {
                //         // sum ratings
                //         const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
                //         // to 2 decimalplaces
                //         data[index].rate = parseFloat(rate.toFixed(1))

                //         // data.push(data[index])
                //         }
                //     }
                // })()

                // this.vendors = resp.data.businesses
            }
        }
    }
</script>

<style lang="scss" >
// custom
@import '@/assets/scss/custom_variables.scss';

    .tabs li.is-active a {
        border-bottom-color: $primary !important;
        color: $primary !important;
    }
    .white {
        color: $white !important;
    }

    .carousel-items {
        border-radius: 1rem;
    }
    .slider-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    .space {
        padding: 5px;
    }

    .cov {
        overflow-x: scroll;
        -ms-overflow-style: none;
        -moz-overflow-style: none;
        scrollbar-width: none;
    }

    .cov::-webkit-scrollbar {
    display: none;
    }

    .cov .media-content {
        overflow: hidden;
    }

    .card-content  {
        padding: 1rem 1.25rem  !important;
    }

    .b-tabs .tab-content {
        padding: 3px !important;
    }

    .media-content .b-skeleton {
        margin:  0 !important;
    }
</style>