<template>
    <div>
        <div class="px-3 py-3 footer text-center">
            <div v-if="cartCount !== 0" class=" -cover px-1 " @click="$router.push('/checkout')">
                <!-- <div class="pt-2 px-0 cart-cover"> -->
                
                <b-button type="is-primary" class="p-3 has-text-weight-medium is-5 title" size="is-medium" rounded expanded>
                    <h6 class=" pt-0 m-0 medium" style=""> {{ getCartBusiness }} </h6> 
                    <!-- <p class="p-0 m-0"> -->
                        Order {{ '('+cartCount+')' }} - GH₵{{ formatPrice(totalPrice)}}
                    <!-- </p> -->
                    
                </b-button>
            </div>
            <hr v-if="cartCount !== 0" class="my-3">
            <div class="columns is-mobile">
                <div @click="routeTo('index')"  :class="`column item is-one-quarter ${(checkLink('index') ? 'linkActiveParent' : '')}`" >
                    <div :class="checkLink('index')">
                        <feather class="px-5 pt-1" type="home"></feather>
                        <!-- <h6>Home</h6> -->
                    </div>    
                </div>
                <div @click="routeTo('search')" :class="`column item ${(checkLink('search') ? 'linkActiveParent' : '')}`">
                    <div :class="checkLink('search')">
                        <feather class="px-5 pt-1" type="search"></feather>
                        <!-- <h6>Search </h6> -->
                    </div>
                </div>
                <div @click="routeTo('orders')" :class="`column item ${(checkLink('orders') ? 'linkActiveParent' : '')}`">
                    <div :class="checkLink('orders')">
                        <feather class="px-5 pt-1" type="layers"></feather>
                        <!-- <h6>Orders</h6> -->
                    </div>
                </div>
                <!-- <div class="column item">
                    <div :class="checkLink('notification')" to="/notification">
                        <feather class="px-5 pt-1" type="bell"></feather>
                        <h6>Notifications</h6>
                    </div>
                </div> -->
                <div @click="routeTo('profile')" :class="`column item ${(checkLink('profile') ? 'linkActiveParent' : '')}`">
                    <div :class="checkLink('profile')">
                        <feather class="px-5 pt-1" type="user"></feather>
                        <!-- <h6>Profile</h6> -->
                    </div>
                </div>
            </div>
        </div>
        <!-- <b-button id="fab" class="fab-wrapper" style="right: 5vw; bottom: 4vh; z-index: 999; position: fixed;" icon-left="plus" type="is-dark" rounded size="is-medium"></b-button> -->
        <!-- <fab :bgColor="bgColor"  icon-size="small" :position="position" @cart="cart" @wishlist="wishlist" :actions="fabActions"></fab> -->
    </div>
</template>

<script>
    import fab from 'vue-fab';

    import { mapGetters, mapActions} from 'vuex'

    export default {
        name: "MainFooter",
        components: {
            // fab
        },
        data() {
            return {
                linkActive: '',
                bgColor: '#007bff',
                position: 'bottom-middle',
                fabActions: [
                                {
                                    name: 'cart',
                                    icon: 'shopping_cart'
                                },
                                {
                                    name: 'wishlist',
                                    icon: 'list'
                                }
                            ]
            }
        },
        computed: {
            ...mapGetters('cart', ['totalPrice']),
            ...mapGetters('cart', ['cartCount']),
            ...mapGetters('cart', ['getCartBusiness']),
        },
        methods: {
            formatPrice(price) {
                return price.toFixed(2)
            },
            getDiscount(price, disc) {
                return ((100 - disc)/100) * price;
            },
            routeTo(link) {
                if (link == this.$route.path.substr(1)) {
                    return
                }
                this.$router.push('/'+link)
            },
            checkLink(menu){
                //get link
                const link = this.$route.path.substr(1)

                if (link == menu){
                    return 'linkActive'
                } else {
                    return ''
                }

            },
            cart() {
                console.log('cart')
            },
            wishlist() {
                console.log('wishlist')
            }
        }
    }
</script>

<style lang="scss" scoped>
// custom
@import '@/assets/scss/custom_variables.scss';

 #fab{
    padding-right: 1.25rem;
    padding-left: 1.25rem;
 }

 .footer {
    // box-shadow: 0px 8px 24px;
    position:fixed;
    width:100%;
    background:white;
    bottom:0
 }

 .text-center {
    text-align: center;
 }

 div.item:hover,  div.item:focus {
    transition: ease-out;
    cursor: pointer;
    background: rgba($color: rgb(230, 230, 230), $alpha: 0.3);
 }

 .linkActive {
    color: $primary !important;
 }

.linkActiveParent {
        
    background: rgba($color: rgb(230, 230, 230), $alpha: 0.3);
}

 h6 {
    font-size: 0.7rem;
    margin-top: -3x;
 }

  .column a { 
    color: hsl(0deg, 0%, 21%);
  }

  .fab-wrapper {
    bottom: 9vh !important;
  }
</style>