
//
import { GET_BUSINESS_QUERY, GET_LOCATION_BUSINESS_QUERY } from '../../graphql/business'
import { apolloClient } from '../../graphql/apollo'

const address = localStorage.getItem('address')
const initialAddress = address ? address : ''

export const vendors = {
    namespaced: true,
    state: { 
        address1: [5.6305863,-0.1474174],
        address: '',
        vendors: []
    },
    getters: {
        discountedMenu: (state) => {
            //
            const data = state.vendors
        
            //
            const disContent = []
        
            for (let index = 0; index < data.length; index++) {
              // const element = array[index];
              if (data[index].menuDiscount > 0) {
                //
                disContent.push(data[index])
              }
            }
        
            // sort in desc
            disContent.sort((a, b) => b.menuDiscount - a.menuDiscount)
        
            return disContent
          },
          freeDelivery: (state) => {
            //
            const data = state.vendors
        
            //
            const freeContent = []
        
            for (let index = 0; index < data.length; index++) {
              // const element = array[index];
              if (data[index].freeDelivery) {
                //
                freeContent.push(data[index])
              }
            }
        
            return freeContent
          },
          mostRated: (state) => {
            //
            const data = state.vendors
            const rateContent = []
        
            for (let index = 0; index < data.length; index++) {
              //
              if (data[index].rate > 0) {
                //
                if (data[index].rate >= 4.5) {
                  //
                  rateContent.push(data[index])
                }
                // data.push(data[index])
              }
            }
            // sort in desc
            rateContent.sort((a, b) => b.rate - a.rate)
        
            return rateContent
          },
          newBusiness: (state) => {
            //
            const data = state.vendors
            const newContent = []
        
            for (let index = 0; index < data.length; index++) {
              //
              if (parseFloat(data[index].ratings.length) === 0) {
                //
                newContent.push(data[index])
                // data.push(data[index])
              }
            }
            // sort in desc
            // newContent.sort((a, b) => b.rate - a.rate)
        
            return newContent
          },
          allBusiness: (state) => {
            //
            return state.vendors
          },
          allShortDelivery: (state) => {
            //
            const data = state.vendors
            const newContent = []
        
            for (let index = 0; index < data.length; index++) {
              //
              if (index) {
                //
                newContent.push(data[index])
                // data.push(data[index])
              }
            }
        
            // sort in desc
            newContent.sort((a, b) => a.deliveryTime.substr(0, 2) - b.deliveryTime.substr(0, 2))
        
            return newContent
          },
          currentBusiness: state => (name) => {
            // if (business) { return }
        
            const results = state.vendors.find(({business}) => business.includes(name))
        
            return results
          }
    },
    actions: {
        async getBusiness({ state,commit }, address ) {
            //
            let resp
            let data
            //
            if (address) {
                resp = await apolloClient.query({ query: GET_LOCATION_BUSINESS_QUERY, variables: { location: state.address1 } })
                data = resp.data.getBusiness

            }  else {
                //
                resp = await apolloClient.query({ query: GET_BUSINESS_QUERY })
                data = resp.data.businesses
            }

            (function () {
            for (let index = 0; index < data.length; index++) {
                // const element = array[index];
                const rateLen = data[index].ratings.length

                if (rateLen > 0) {
                // sum ratings
                const rate = (data[index].ratings.reduce((a, b) => a + b, 0)) / rateLen
                // to 2 decimalplaces
                data[index].rate = parseFloat(rate.toFixed(1))

                // data.push(data[index])
                }
            }
            })()

            commit('updateVendors', data)
        }
    },
    mutations: {
        updateVendors: (state, data) => {
            state.vendors = data
        }
    }
}